<!-- 人才理念 -->
<template>
  <div class="container">
    <div class="row gy-2">
      <div
        class="col-12 col-xl-12 col-xxl-12 item-box"
        v-for="item in people"
        :key="item"
      >
        <div class="item">
          <div class="text">
            <h5>理念 一</h5>
            <p>人才是科技创新的决定性因素，</p>
            <p>公司采取引进、</p>
            <p>培养相结合的人才策略！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getPeople } from '../../../server/join'

const people = ref(null)

onMounted(async () => {
  people.value = await getPeople()
})
</script>
<style lang="stylus" scoped>
.row
  margin-bottom 30px
  margin-top 30px
.item
  height 370px
  width 100%
  background-repeat no-repeat
  background-position center center
  background-size auto
  background-image: url('../../../assets/30.png')
  position relative
  @media screen and (max-width 768px) {
    height 200px
  }
  .text
    position absolute
    left 10%
    top 10%
    & > h5
      font-size: 50px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom 20px
      @media screen and (max-width 768px) {
        font-size: 20px;
      }
    & > p
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom 0
      @media screen and (max-width 768px) {
        font-size: 14px;
      }
.item-box
  &:nth-child(2n+0)
    .text
      position absolute
      right 5%
      top 5%
      left auto
</style>
